import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_API_URL;

class FileService {
	getAll() {
		return axios.get(API_URL + 'files', {headers: authHeader()});
	}

	commit(data) {
		return axios.post(API_URL + `files`, data, {headers: authHeader()});
	}

	get(id) {
		return axios.get(API_URL + `file/${id}`, {headers: authHeader(), responseType: 'arraybuffer'});
	}

	delete(id) {
		return axios.delete(API_URL + `file/${id}`, {headers: authHeader()});
	}

	search(searchword) {
		return axios.get(API_URL + `files?searchword=${searchword}`, {headers: authHeader()});
	}
}

export default new FileService();
