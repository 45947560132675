<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Files</h1>
				<Form @submit="searchBySearchword">
					<div class="input-group mb-3">
						<Field
							name="searchword"
							type="search"
							autocomplete="off"
							id="searchword"
							class="form-control"
							placeholder="Search"
							v-model="searchword"
							:rules="validateSearchRequired"
						/>
						<div class="input-group-append">
							<button class="btn btn-primary ms-3" type="button" @click="searchBySearchword">Search</button>
							<button class="btn btn-primary ms-3" type="button" @click="resetForm">X</button>
						</div>
					</div>
					<ErrorMessage name="searchword" class="error-feedback d-block alert alert-warning" />
				</Form>

				<ul class="list-group mb-3">
					<li class="list-group-item d-flex justify-content-between" v-for="(file, index) in files" :key="index">
						{{ file.filename }} ({{ bytesToSize(file.size) }})
						<div>
							<a class="btn btn-primary me-2" @click="confirmDelete(file.id)">Delete</a>
							<a class="btn btn-primary me-2" @click="getFile(file.id, file.filename)">Download</a>
						</div>
					</li>
				</ul>

				<p v-if="message" class="error-feedback d-block alert alert-warning mb-3">{{ message }}</p>

				<file-pond
					name="filepond"
					ref="pond"
					accepted-file-types="image/jpeg, image/png, application/pdf"
					v-on:init="handleFilePondInit"
					label-idle="Drag n Drop Files"
					allow-revert="false"
					allow-remove="false"
					allow-multiple="true"
					credits="false"
				/>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>

	<confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import FileService from '../services/file.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import ConfirmDialogue from '../components/_ConfirmDialogue.vue';
import vueFilePond, {setOptions} from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

const API_URL = process.env.VUE_APP_API_URL;
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
	name: 'AppFiles',
	components: {
		FilePond,
		Form,
		Field,
		ErrorMessage,
		ConfirmDialogue,
	},
	data() {
		return {
			files: [],
			message: '',
			searchword: '',
		};
	},
	methods: {
		authFileHeader() {
			let user = JSON.parse(localStorage.getItem('user'));
			if (user && user.accessToken) {
				return {Authorization: 'Bearer ' + user.accessToken};
			} else {
				return {};
			}
		},
		async confirmDelete(id) {
			const confirmId = id;
			const ok = await this.$refs.confirmDialogue.show({
				message: 'Are you sure you want to delete this file? It cannot be undone.',
				okButton: 'Yes',
				cancelButton: 'No',
			});

			if (ok) {
				this.deleteFile(confirmId);
			}
		},
		retrieveFiles() {
			FileService.getAll()
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.files = response.data.files;
					} else {
						this.files = [];
						this.message = response.data.error.message;
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		commitFile(id) {
			FileService.commit({filepond: id})
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.retrieveFiles();
					} else {
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		deleteFile(id) {
			FileService.delete(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.retrieveFiles();
					} else {
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		getFile(id, filename) {
			FileService.get(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						// Nothing
						this.forceFileDownload(response, filename);
					} else {
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		forceFileDownload(response, filename) {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		},
		handleFilePondInit: function () {
			setOptions({
				server: {
					url: API_URL,
					process: {
						url: './files',
						method: 'POST',
						withCredentials: false,
						headers: this.authFileHeader(),
						onload: (response) => {
							this.commitFile(response);
						},
					},
				},
				credits: '',
			});
		},
		searchBySearchword() {
			this.message = '';
			FileService.search(this.searchword)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.files = response.data.files;
					} else {
						this.files = [];
						this.message = response.data.error.message;
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		resetForm() {
			this.message = '';
			this.searchword = '';
			this.retrieveFiles();
		},
		validateSearchRequired(value) {
			if (value && value.trim()) {
				if (value.length > 3) {
					return true;
				} else {
					this.message = '';
					return 'Min 3 chars';
				}
			}
			return true;
		},
		bytesToSize(bytes) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes === 0) return 'n/a';
			const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
			if (i === 0) return `${bytes} ${sizes[i]})`;
			return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			}
		},
	},
	computed: {},
	watch: {},
	created() {
		this.retrieveFiles();
	},
	mounted() {},
};
</script>
